import React from "react";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

const PrivacyPolicy = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <Button colorScheme="red" onClick={onOpen}>
        Privacy Policy
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Privacy Policy</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH="400px" overflowY="auto">
            <Text mb={4}>
              At <strong>Flypi</strong>, we recognize that privacy is of paramount importance and are committed to
              maintaining it. This Privacy Policy provides information about the personal information we collect and how
              we use it.
            </Text>
            <Text mb={4}>
              <strong>Personal Information We Collect:</strong>
              <ul>
                <li>
                  Information provided during registration (e.g., name, address, phone number, email).
                </li>
                <li>
                  Details of transactions made on our website (e.g., products purchased, time, date, and payments).
                </li>
                <li>Any other information you send to us.</li>
              </ul>
            </Text>
            <Text mb={4}>
              <strong>Use of Personal Information:</strong>
              <ul>
                <li>Responding to your queries or information requests.</li>
                <li>Enabling you to make orders for our products.</li>
                <li>
                  Processing functions related to this website, such as delivery, administration, customer service, or
                  promotional offers.
                </li>
                <li>Creating customer profiles based on browsing or purchasing history.</li>
              </ul>
            </Text>
            <Text mb={4}>
              <strong>Disclosure of Personal Information:</strong> Your personal information will not be disclosed for
              purposes other than those mentioned, except in the following circumstances:
              <ul>
                <li>If it is for a directly related purpose that you would reasonably expect.</li>
                <li>Where you have consented to the use or disclosure.</li>
                <li>For law enforcement purposes.</li>
              </ul>
            </Text>
            <Text mb={4}>
              <strong>Opt-out of Further Usage:</strong> Users may opt out of receiving offers or promotional materials
              by emailing <strong>info@Flypi.in</strong>. We will process the change within three days and confirm the
              update.
            </Text>
            <Text mb={4}>
              <strong>Data Protection:</strong> We use various security technologies and procedures, such as passwords
              and physical security measures, to protect your personal information. However, no data transmission is
              100% secure, and we cannot guarantee absolute protection.
            </Text>
            <Text>
              <strong>Changes to Privacy Policy:</strong> We reserve the right to modify this Privacy Policy at any
              time. Updates will be posted on this page, and we encourage you to review it regularly.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={onClose}>
              Accept
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Decline
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PrivacyPolicy;
