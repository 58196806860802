import React, { useState, useEffect } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Textarea, Select, useToast, Stack } from '@chakra-ui/react';
import axios from 'axios';

const RegisterFoodItem = () => {
  const [foodData, setFoodData] = useState({
    name: '',
    description: '',
    category: '',
    price: '',
    imageUrl: '',  // Changed to imageUrl instead of image (URL input)
    restaurantId: '',  // Added restaurantId field
  });
  const [restaurants, setRestaurants] = useState([]);  // Store restaurant list
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  // Fetch restaurants on component mount
  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const response = await axios.get('http://localhost:5600/api/restaurants/');
        setRestaurants(response.data);  // Assuming the response contains an array of restaurants
      } catch (error) {
        toast({
          title: 'Error',
          description: 'Failed to fetch restaurants.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchRestaurants();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFoodData({ ...foodData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Debugging: Check form data before submission
    console.log("Form data before submit:", foodData);
    
    // Check if all fields are filled before submission
    if (!foodData.name || !foodData.description || !foodData.category || !foodData.price || !foodData.imageUrl || !foodData.restaurantId) {
      toast({
        title: 'Error',
        description: 'Please fill in all fields before submitting.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return; // Prevent submission if any field is empty
    }

    const formData = {
      name: foodData.name,
      description: foodData.description,
      category: foodData.category,
      price: foodData.price,
      imageUrl: foodData.imageUrl,  // Send image URL directly
      restaurantId: foodData.restaurantId,  // Include restaurantId in the formData
    };

    try {
      setLoading(true);
      const response = await axios.post('http://localhost:5600/api/food-items/', formData);
      setLoading(false);
      
      // Debugging: Check server response
      console.log("Server response:", response.data);
      
      toast({
        title: 'Food Item Registered',
        description: `Food item "${response.data.name}" was successfully registered!`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      setFoodData({
        name: '',
        description: '',
        category: '',
        price: '',
        imageUrl: '',  // Reset imageUrl
        restaurantId: '',  // Reset restaurantId
      });
    } catch (error) {
      setLoading(false);
      console.error("Error occurred during submission:", error); // Debugging error logs
      toast({
        title: 'Error',
        description: 'Something went wrong while registering the food item.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box width="500px" mx="auto" p="4" borderRadius="md" boxShadow="md" bg="white">
      <form onSubmit={handleSubmit}>
        <Stack spacing={4}>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input type="text" name="name" value={foodData.name} onChange={handleChange} required />
          </FormControl>

          <FormControl>
            <FormLabel>Description</FormLabel>
            <Textarea name="description" value={foodData.description} onChange={handleChange} required />
          </FormControl>

          <FormControl>
            <FormLabel>Category</FormLabel>
            <Select name="category" value={foodData.category} onChange={handleChange} required>
              <option value="appetizer">Appetizer</option>
              <option value="main_course">Main Course</option>
              <option value="dessert">Dessert</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Price</FormLabel>
            <Input type="number" name="price" value={foodData.price} onChange={handleChange} required />
          </FormControl>

          <FormControl>
            <FormLabel>Image URL</FormLabel>
            <Input
              type="text"
              name="imageUrl"
              value={foodData.imageUrl}
              onChange={handleChange}
              placeholder="Enter image URL"
              required
            />
            {foodData.imageUrl && (
              <Box mt="2">
                <img src={foodData.imageUrl} alt="Preview" style={{ width: '150px', height: 'auto' }} />
              </Box>
            )}
          </FormControl>

          <FormControl>
            <FormLabel>Restaurant</FormLabel>
            <Select name="restaurantId" value={foodData.restaurantId} onChange={handleChange} required>
              {restaurants.map((restaurant) => (
                <option key={restaurant._id} value={restaurant._id}>
                  {restaurant.name}
                </option>
              ))}
            </Select>
          </FormControl>

          <Button
            type="submit"
            colorScheme="blue"
            isFullWidth
            isLoading={loading}
            loadingText="Registering"
          >
            Register Food Item
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default RegisterFoodItem;
