import React from "react";
import { Box, Text, VStack, HStack } from "@chakra-ui/react";
import Header from "./Header";

import briyaniImage from "../assests/bq.jpeg";
import tome from "../assests/tome.png"
import punjabi from "../assests/Punjabi Da Dhaba_2.1.png"
import shake from "../assests/ss.jpg"

import RestaurantCard from "./RestaurantCard";
import Footer from "./Footer";


const Home = () => {
  const restaurants = [
    {
      id: 1,
      name: "Briyani Queen",
      description: "Savor the royal taste of authentic biryanis at Biryani Queen, where flavor reigns supreme.",
      image: briyaniImage,
      tags: ["Briyani", "Veg","Non-Veg"],
      menu: [
        { name: "Veg Briyani (200gm)", price: 120 },
        { name: "Biryani Queens Special (Chicken) (200gm)", price: 150 },
        { name: "Chicken Tikka Briyani (4 pcs)", price: 180 },
        { name: "Afgani Chicken (4 pcs)", price: 200},
        { name: "Chicken Seekh Kebabs (2 pcs)", price: 100},
        { name: "Mutton Seekh Kebabs (2 pos)", price: 120},
        { name: "Paneer Tikka (4pcs)", price: 120},
        { name: "Chicken orley kabab", price: 180},
        { name: "Chicken nuggets", price: 160},
      ],
    },
    
    {
        id: 2,
        name: "Shake Studio",
        description: "VISIT SHAKE STUDIO TO GET THE VIBE OF THE BEST CHILLING PLACE IN INDIRAPURAM",
        image: shake,
        tags: ["Beverages", "Shakes","Burgers","Wraps","Sandwich","Fries","Pasta","Salads","Maggie"],
        menu: [
          { name: "Aloo tikki Burger", price: 59 },
          { name: "Veg Burger", price: 69 },
          { name: "Peri Peri Burger", price: 99 },
          {name: "Masala Grill Burger",price: 99},
          {name: "Crispy Paneer Burger",price: 109},
          {name:"Chicken Burger",price: 109},

          {name:"Veg Wrap",price: 79},
          {name:"Crispy Paneer Wrap",price: 99},
          {name:"Falafel Wrap",price: 99},
          {name:"Egg Wrap",price: 99},
          {name:"Chicken Wrap",price: 119},

          {name:"Veg Grilled Sandwich",price: 60},
          {name:"Paneer Grilled Sandwich",price: 70},
          {name: "Cheese Corn Sandwich", price: 70},
          {name: "Paneer Makhani Sandwich", price: 80},
          {name: "Cheesy Veg Sandwich", price: 80},
          {name: "Spicy Chicken Sandwich", price: 90},

          {name: "Salted fries", price: 70},
          {name: "Peri Peri Fries", price: 90},
          {name: "Cheesy Fries", price: 120},
          {name: "Fries With Chicks", price: 150},
          {name: "Chicken Pop Corn Fries", price: 160},

          {name: "White Sauce Pasta", price: 110},
          {name: "Mix Sauce Pasta", price: 130},
          {name: "Red Sauce Pasta", price: 90},

          {name: "Fruit Punch Salad", price: 110},
          {name: "Watermelon Feta Salad", price: 160},
          {name: "Paneer Salad", price: 120},
          {name: "Boiled Chicks Salad", price: 140},

          {name: "Plain Maggie", price: 50},
          {name: "Double Masala", price: 60},
          {name: "Veg Maggie", price: 70},
          {name: "Cheese Maggie", price: 90},
          {name: "Peri Peri Maggie", price: 90},
        
        ],
      },
      {
        id: 3,
        name: "Taste Of Middle East",
        description: "Experience the rich flavors and authentic cuisine of the Middle East at Taste of Middle East.",
        image: tome,
        tags: ["Non-Veg", "Chicken"],
        menu: [
          { name: "Butter Chicken", price: 160 },
          { name: "Shahi Paneer", price: 120 },
          { name: "Tandoori Naan", price: 90 },
        ],
      },
      {
        id: 4,
        name: "Punjabi Da Dhaba",
        description: "Relish the hearty and authentic flavors of Punjab at Punjabi Da Dhaba, where every meal feels like home.",
        image: punjabi,
        tags: ["Food", "Burgers"],
        menu: [
          { name: "Cheeseburger", price: 8 },
          { name: "Fries", price: 5 },
          { name: "Coke", price: 2 },
        ],
      },
      
  ];

  return (
    <>
      <Header />
      <HStack h="100vh" w="100%" overflow="hidden">
        <VStack h="100%" w="100%" backgroundColor="white">
          <Box p="2" w="100%">
            <Text fontSize="2xl" fontWeight="bold" mb="4">
              Restaurants
            </Text>
            {/* Scrollable container for cards */}
            <Box
              display="grid"
              gridTemplateColumns={{
                base: "1fr", // 1 column for small screens
                md: "repeat(2, 1fr)", // 2 columns for medium screens
                lg: "repeat(3, 1fr)", // 3 columns for large screens
              }}
              gap={5}
              overflowY="auto"
              maxH="82vh"
              p={8}
              borderWidth={0}
              borderColor="gray.200"
              borderRadius="lg"
              boxShadow="lg"
            >
              {restaurants.map((restaurant) => (
                <RestaurantCard key={restaurant.id} restaurant={restaurant} />
              ))}
            </Box>
          </Box>
          <Footer/>
        </VStack>
      </HStack>
     
    </>
  );
};

export default Home;
