import {
  Button,
  HStack,
  Box,
  IconButton,
  Image,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  useDisclosure,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { FaBars, FaUserMinus } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import TermsAndConditions from './TermsAndConditions'; // Adjust path as necessary
import foodAppLogo from '../assests/Vdel-modified.png'; // Replace with your PNG image path
import PrivacyPolicy from './PrivacyPolicy';
import RefundPolicies from './RefundPolicies';

const Header = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleLogout = () => {
    navigate('/');
  };

  const handleDownloadClick = () => {
    navigate('/download');
  };

  const logoutButton = (
    <Button
      onClick={handleLogout}
      variant="outline"
      borderColor="white"
      color="white"
      _hover={{ bgColor: 'whiteAlpha.300' }}
      size="md"
      px={6}
    >
      Logout
    </Button>
  );

  return (
    <Box bgColor="#C21010" px={4} py={3} boxShadow="sm" w="100%">
      <HStack
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        maxW="1200px"
        mx="auto"
        spacing={8}
      >
        {/* Hamburger Button for Drawer */}
        <IconButton
          aria-label="Open Menu"
          icon={<FaBars />}
          onClick={onOpen}
          size="md"
          bgColor="transparent"
          color="white"
          _hover={{ bgColor: 'whiteAlpha.300' }}
        />

        {/* Drawer for Navigation */}
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerBody>
              <VStack spacing={4} align="stretch">
                {/* Download Button */}
                <Button
                  onClick={() => {
                    onClose();
                    handleDownloadClick();
                  }}
                  variant="solid"
                  colorScheme="red"
                >
                  Download
                </Button>

                {/* Terms and Conditions */}

                <TermsAndConditions />
                <PrivacyPolicy/>
                <RefundPolicies/>
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>

        {/* Logo Section */}
        <Text
          onClick={handleLogout}
          fontSize="2xl"
          fontWeight="bold"
          color="white"
          cursor="pointer"
          letterSpacing="wide"
        >
          FLYPI.IN
        </Text>

      </HStack>
    </Box>
  );
};

export default Header;
