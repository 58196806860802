// src/components/CartButton.js
import React from 'react';
import { Box, Text, Button, HStack, Icon } from '@chakra-ui/react';
import { useCart } from '../context/cartcontext';
import { FaShoppingCart } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const CartButton = () => {
  const { cart, getTotalPrice } = useCart();
  const navigate = useNavigate(); // Hook to navigate to the cart page

  return (
    <Box position="fixed" bottom="4" right="4" zIndex="1000">
      <HStack spacing={4}>
        <Button
          leftIcon={<Icon as={FaShoppingCart} />}
          colorScheme="teal"
          variant="solid"
          size="lg"
          onClick={() => navigate('/cart')}  // Navigate to the Cart page
        >
          <Text>{cart.length} Items</Text>
        </Button>
        <Box>
          <Text fontSize="xl">₹{getTotalPrice().toFixed(2)}</Text>
        </Box>
      </HStack>
    </Box>
  );
};

export default CartButton;
