import React from 'react';
import { Box, Text, Image, Button, VStack, HStack, Divider, Flex } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import { useCart } from '../context/cartcontext';

const RestaurantDetails = () => {
  const { state } = useLocation();
  const { restaurant } = state;
  const { addToCart } = useCart();

  return (
    <>
      <Header />
      <Flex 
        h="100vh"
        w="100%"
        direction={{ base: 'column', md: 'row' }}
        overflow="hidden"
        backgroundColor="white"
      >
        <Flex
          h={{ base: 'auto', md: '100%' }}
          w="100%"
          p={{ base: 2, md: 4 }}
          justify="center"
          align="center"
          bg="gray.100"
        >
          <Box
            p="4"
            bg="white"
            borderRadius="lg"
            boxShadow="lg"
            w="100%"
            maxW={{ base: '90%', md: '800px' }}
            maxH="calc(100vh - 64px)" // Adjust height for header
            overflowY="auto" // Enable vertical scrolling
          >
            <Image
              src={restaurant.image}
              alt={restaurant.name}
              borderRadius="md"
              maxW="100%"
              maxH={{ base: '200px', md: '400px' }}
              objectFit="cover"
              mx="auto"
            />
            <Text
              fontSize={{ base: 'xl', md: '2xl' }}
              fontWeight="bold"
              mt="4"
              textAlign="center"
            >
              {restaurant.name}
            </Text>
            <Text
              fontSize={{ base: 'sm', md: 'md' }}
              color="gray.500"
              mb="4"
              textAlign="center"
            >
              {restaurant.description}
            </Text>
            <Divider />
            <VStack align="stretch" mt="6" spacing="4">
              {restaurant.menu.map((item, index) => (
                <Flex
                  key={index}
                  justify="space-between"
                  p="4"
                  borderRadius="md"
                  bg="gray.50"
                  boxShadow="sm"
                  direction={{ base: 'column', md: 'row' }}
                >
                  <VStack align="start" spacing="2">
                    <Text fontSize={{ base: 'sm', md: 'md' }}>{item.name}</Text>
                    <Text fontSize={{ base: 'sm', md: 'md' }} color="gray.600">
                      ₹{item.price.toFixed(2)}
                    </Text>
                  </VStack>
                  <Button
                    size={{ base: 'sm', md: 'md' }}
                    colorScheme="teal"
                    mt={{ base: 2, md: 0 }}
                    onClick={() => addToCart(item)}
                  >
                    Add to Cart
                  </Button>
                </Flex>
              ))}
            </VStack>
            <Divider mt="6" />
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default RestaurantDetails;
