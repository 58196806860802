// src/components/PaymentSuccess.js
import React, { useEffect, useState } from 'react';
import { Box, Text, Button } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

const PaymentSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [reference, setReference] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const reference = queryParams.get('reference');
    setReference(reference);
  }, [location]);

  return (
    <Box p={6}>
      <Text fontSize="3xl" fontWeight="bold" mb={4}>
        Payment Successful
      </Text>
      <Text mb={4}>Your payment was successful! Collect Your order at the Counter. Reference: {reference}</Text>
      <Button onClick={() => navigate('/')}>Go Back to Home</Button>
    </Box>
  );
};

export default PaymentSuccess;