import React from "react";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

const RefundPolicies = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <Button colorScheme="red" onClick={onOpen}>
        Refund & Cancellation Policies
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Refund & Cancellation Policies</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH="400px" overflowY="auto">
            <Text mb={4}>
              <strong>Online Payment:</strong> For online orders placed on Flypi, the payment information, card
              information, and bank details are entered on third-party websites/bank websites. Flypi does not take any
              card information at any point in time from the customer. However, Flypi ensures that all third-party links
              for payment have the required SSL certificate and maintain complete security of the customer data.
            </Text>
            <Text mb={4}>
              Customers can make payment through credit card, debit card, net banking, and/or cash on delivery.
            </Text>

            <Text mb={4}>
              <strong>Shipping Policy:</strong> Flypi accepts online orders from a 5 Km radius of the restaurants. Flypi
              takes full responsibility to send the order in insulated bags which maintain the temperature of the items.
              Flypi uses only self-owned bikes and cars for delivery and no third-party courier service is used.
              However, once the order is handed over, the temperature maintenance responsibility lies with the receiver.
            </Text>

            <Text mb={4}>
              <strong>Cancellation:</strong> You can cancel your order through phone (9355818985) and email to
              info@flypi.in. Your order amount less the payment gateway charges will be refunded.
            </Text>
            <Text mb={4}>
              In case the preparation of your order has started, you cannot cancel the order. There will be no refund in
              such an event. In the event where Flypi is not able to deliver the product due to no-stock or other
              reasons, the entire order amount will be refunded.
            </Text>

            <Text>
              <strong>Refund:</strong> Once you request the cancellation of your order, it will take us a minimum of 24
              hours to initiate a refund. You will be notified of the same by email/SMS. Having the money transferred
              back to the source of transaction may take up to 7-10 business days for the respective banks to process the
              refund.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={onClose}>
              Accept
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Decline
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default RefundPolicies;
