import React, { createContext, useState, useContext } from 'react';

// Create a Cart Context
const CartContext = createContext();

// Cart Provider component to wrap around the application
export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);

  // Add an item to the cart
  const addToCart = (item) => {
    setCart((prevCart) => [...prevCart, item]);
  };

  // Remove a single instance of an item from the cart
  const removeFromCart = (itemId) => {
    setCart((prevCart) => {
      const itemIndex = prevCart.findIndex(item => item.id === itemId);
      if (itemIndex !== -1) {
        const updatedCart = [...prevCart];
        updatedCart.splice(itemIndex, 1); // Remove only the first matching item
        return updatedCart;
      }
      return prevCart; // If item is not found, return the original cart
    });
  };

  // Calculate the total price of items in the cart
  const getTotalPrice = () => {
    return cart.reduce((total, item) => total + item.price, 0);
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, getTotalPrice }}>
      {children}
    </CartContext.Provider>
  );
};

// Custom hook to use cart context
export const useCart = () => useContext(CartContext);
