import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import RegisterRestaurant from './components/RegisterRestaurant';
import RegisterFoodItem from './components/RegisterFoodItem';  // Import the new component
import GoogleDriveDownloadButton from './components/Download';
import NotFound from './components/NotFound';
import Home from './components/Home';
import RestaurantDetails from './components/RestaurantDetails.jsx';
import CartButton from './components/cartButton.jsx';  // New cart button component
import { CartProvider } from './context/cartcontext.jsx'; // Import the CartProvider
import CartPage from './components/CartPage.jsx';
import PaymentSuccess from './components/paymentSuccess.jsx';
import Dashboard from './components/Dashboard.jsx';
import TermsAndConditions from './components/TermsAndConditions.jsx';
import PrivacyPolicy from './components/PrivacyPolicy.jsx';
import RefundPolicies from './components/RefundPolicies.jsx';
// import LiveStreamPlayer from './components/LiveVideoPlayer.jsx';







const App = () => {
  return (
    <CartProvider>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/register" element={<RegisterRestaurant />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/restaurant/:id" element={<RestaurantDetails />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/registerfood" element={<RegisterFoodItem />} />
          <Route path="/download" element={<GoogleDriveDownloadButton />} />
          <Route path="/*" element={<NotFound />} />
          <Route path="/paymentsuccess" element={<PaymentSuccess/>} />
          <Route path="/admindash" element={<Dashboard />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />}/>
          <Route path="/privacy-policy" element={<PrivacyPolicy />} /> 
          <Route path="/refund-policies" element={<RefundPolicies/>}/>
          {/* <Route path="/livevideo" element={<LiveStreamPlayer/>}/> */}
        </Routes>

        {/* Floating Cart Button */}
        <CartButton />
      </BrowserRouter>
    </CartProvider>
  );
};

export default App;
