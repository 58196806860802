import React, { useState } from 'react';
import { Box, Text, Button, VStack, Divider, HStack, Input, Radio, RadioGroup } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../context/cartcontext';
import axios from 'axios';
import Header from './Header';

const CartPage = () => {
  const { cart, removeFromCart, getTotalPrice } = useCart();
  const navigate = useNavigate();

  const [customerDetails, setCustomerDetails] = useState({
    name: '',
    email: '',
    contact: '',
  });
  const [paymentMethod, setPaymentMethod] = useState('online'); // Default to Razorpay (online)
  const [isFormValid, setIsFormValid] = useState(false);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Validate form fields
  const validateForm = () => {
    const { name, email, contact } = customerDetails;
    if (name && email && contact) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  // Handle Razorpay payment
  const handlePayment = async () => {
    if (paymentMethod === 'cod') {
      // Handle COD order
      await handleCODOrder();
    } else {
      // Handle Razorpay payment
      try {
        const totalAmount = getTotalPrice();
        
        // Make API call to get Razorpay API key and create order
        const { data: { key } } = await axios.get('https://foodwebbackend-ufii.onrender.com/api/getkey');
        const { data } = await axios.post('https://foodwebbackend-ufii.onrender.com/api/checkout', { amount: totalAmount });

        const options = {
          key,
          amount: data.order.amount, // amount in paise
          currency: "INR",
          name: "Flypi.in",
          description: "Order Payment",
          order_id: data.order.id,
          handler: async function (response) {
            const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response;

            // Send the cart items with their quantities to the backend
            const orderDetails = cart.map(item => ({
              name: item.name,
              quantity: item.quantity,
              price: item.price,
            }));

            const paymentData = {
              razorpay_order_id,
              razorpay_payment_id,
              razorpay_signature,
              amount: totalAmount,
              customerDetails, // Send the customer details here
              orderDetails,    // Send the order details (menu items and quantities)
            };

            // Call backend for payment verification
            const verificationResponse = await axios.post(
              "https://foodwebbackend-ufii.onrender.com/api/paymentverification",
              paymentData
            );

            if (verificationResponse.data.success) {
              navigate("/paymentsuccess");
            } else {
              alert("Payment verification failed.");
            }
          },
          prefill: {
            name: customerDetails.name,
            email: customerDetails.email,
            contact: customerDetails.contact,
          },
        };

        // Open Razorpay payment modal
        const razorpay = new window.Razorpay(options);
        razorpay.open();
      } catch (error) {
        alert('Error while processing payment.');
      }
    }
  };

  // Handle COD order
  const handleCODOrder = async () => {
    try {
      const totalAmount = getTotalPrice();
      const orderDetails = cart.map(item => ({
        name: item.name,
        quantity: item.quantity,
        price: item.price,
      }));

      const codData = {
        amount: totalAmount,
        customerDetails,
        orderDetails,
      };

      // Make API call to handle COD order
      const response = await axios.post("https://foodwebbackend-ufii.onrender.com/api/orders/create-cod", codData);
    
      if (response.data.success) {
        navigate("/paymentsuccess");
      } else {
        alert("Error placing COD order.");
      }
    } catch (error) {
      alert('Error processing COD order.');
    }
  };

  return (
    <>
      <Header />
      <HStack h="100vh" w="100%" overflow="hidden">
        <VStack h="100%" w="100%" p={4} backgroundColor="white">
          <Box p={6} w="100%" overflowY="auto" maxH="calc(100vh - 64px)">
            <Button
              leftIcon={<Text>←</Text>}
              colorScheme="teal"
              variant="outline"
              onClick={() => navigate(-1)}
              mb={4}
            >
              Back
            </Button>

            <Text fontSize="3xl" fontWeight="bold" mb={4}>
              Your Cart
            </Text>

            {cart.length === 0 ? (
              <Text>Your cart is empty.</Text>
            ) : (
              <VStack spacing={4}>
                {cart.map((item, index) => (
                  <VStack
                    key={index}
                    align="stretch"
                    w="100%"
                    p={4}
                    borderWidth="1px"
                    borderRadius="md"
                    bg="gray.50"
                    boxShadow="md"
                    _hover={{ bg: 'gray.100' }}
                    spacing={2}
                  >
                    <Text fontSize="lg" fontWeight="bold">{item.name}</Text>
                    <Text fontSize="md" color="gray.600">₹{item.price.toFixed(2)}</Text>
                    <Text fontSize="sm" color="gray.500">Quantity: {item.quantity}</Text>
                    <Button
                      size="sm"
                      colorScheme="red"
                      alignSelf="flex-end"
                      onClick={() => removeFromCart(item.id)}
                    >
                      Remove
                    </Button>
                  </VStack>
                ))}
                <Divider mt={4} />
                <HStack w="100%" justify="space-between" mt={4}>
                  <Text fontSize="xl" fontWeight="bold">Total:</Text>
                  <Text fontSize="xl" fontWeight="bold">₹{getTotalPrice().toFixed(2)}</Text>
                </HStack>

                {/* Proceed to Checkout Button */}
                <Button 
                  colorScheme="teal" 
                  size="lg" 
                  mt={4} 
                  onClick={handlePayment} 
                  isDisabled={!isFormValid}
                >
                  Proceed to Checkout
                </Button>

                {/* Customer Details Form */}
                <Box mt={4}>
                  <Text fontSize="lg" fontWeight="bold">Customer Details</Text>
                  <Input
                    placeholder="Enter your name"
                    name="name"
                    value={customerDetails.name}
                    onChange={handleInputChange}
                    onBlur={validateForm}
                    mb={2}
                  />
                  <Input
                    placeholder="Enter your email"
                    name="email"
                    value={customerDetails.email}
                    onChange={handleInputChange}
                    onBlur={validateForm}
                    mb={2}
                  />
                  <Input
                    placeholder="Enter your contact number"
                    name="contact"
                    value={customerDetails.contact}
                    onChange={handleInputChange}
                    onBlur={validateForm}
                    mb={4}
                  />
                </Box>

                {/* Payment Method Selection */}
                <Box mt={2} mb={20}>
                  <Text fontSize="lg" fontWeight="bold">Select Payment Method</Text>
                  <RadioGroup onChange={setPaymentMethod} value={paymentMethod}>
                    <VStack align="start" mt={2}>
                      <Radio value="online">Online (Razorpay)</Radio>
                      <Radio value="cod">Cash on Delivery (COD)</Radio>
                    </VStack>
                  </RadioGroup>
                </Box>
              </VStack>
            )}
          </Box>
        </VStack>
      </HStack>
    </>
  );
};

export default CartPage;
