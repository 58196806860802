import React from "react";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

const TermsAndConditions = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <Button colorScheme="red" onClick={onOpen}>
        Terms and Conditions
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Terms and Conditions</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH="400px" overflowY="auto">
            <Text mb={4}>
              By accessing and placing an order with <strong>Flypi</strong>, you confirm that you are in agreement
              with and bound by the terms of service contained in the Terms & Conditions outlined below. These terms
              apply to the entire website and any email or other type of communication between you and <strong>Flypi</strong>.
            </Text>
            <Text mb={4}>
              <strong>Flypi</strong> grants you a revocable, non-exclusive, non-transferable, limited license to
              use the website strictly in accordance with the terms of this Agreement. These Terms & Conditions are a
              contract between you and <strong>Flypi</strong> (referred to as "Flypi", "us", "we", or "our"),
              the provider of the Flypi website and the services accessible from the Flypi website (referred
              to as the "Flypi Service").
            </Text>
            <Text mb={4}>
              You are agreeing to be bound by these Terms & Conditions. If you do not agree to these Terms & Conditions,
              please do not use the <strong>Flypi</strong> Service. In these Terms & Conditions, "you" refers both
              to you as an individual and to the entity you represent. If you violate any of these Terms & Conditions,
              we reserve the right to cancel your account or block access to your account without notice.
            </Text>
            <Text>
              <strong>Disclaimer:</strong> Under no circumstances shall the <strong>Flypi</strong> team be liable
              for any direct, indirect, special, incidental, or consequential damages, including, but not limited to,
              loss of data or profit, arising out of the use, or the inability to use, the materials on this site, even
              if the <strong>Flypi</strong> team or an authorized representative has been advised of the
              possibility of such damages. If your use of materials from this site results in the need for servicing,
              repair, or correction of equipment or data, you assume any costs thereof. <strong>Flypi</strong>{" "}
              reserves the right to change prices and revise the resource usage policy at any moment without prior
              notice.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={onClose}>
              Accept
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Decline
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default TermsAndConditions;
