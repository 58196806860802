import React from "react";
import { Box, Image, Badge, Text, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const RestaurantCard = ({ restaurant }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/restaurant/${restaurant.id}`, { state: { restaurant } });
  };

  return (
    <Box
      className="card"
      bg="white"
      w="92"
      boxShadow="xl"
      borderRadius="md"
      overflow="hidden"
      onClick={handleNavigate}
      cursor="pointer"
    >
      <Image src={restaurant.image} alt={restaurant.name} />
      <Box p="2">
        <Text fontWeight="bold" fontSize="lg" mb="2">
          {restaurant.name}{" "}
          <Badge ml="1" colorScheme="purple">
            NEW
          </Badge>
        </Text>
        <Text color="gray.500">{restaurant.description}</Text>
        <Box mt="4">
          {restaurant.tags.map((tag, index) => (
            <Badge
              key={index}
              variant="outline"
              colorScheme="green"
              mr="2"
            >
              {tag}
            </Badge>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default RestaurantCard;
