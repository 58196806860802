import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  VStack,
  Heading,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';

const RegisterRestaurant = () => {
  const [formData, setFormData] = useState({
    name: '',
    location: '',
    cuisine: '',
    contact: '',
    images: ['', '', ''], // Array for 3 image URLs
  });

  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageChange = (index, value) => {
    const updatedImages = [...formData.images];
    updatedImages[index] = value;
    setFormData((prev) => ({
      ...prev,
      images: updatedImages,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Ensure only filled image URLs are sent
      const validImages = formData.images.filter((url) => url.trim() !== '');
      const dataToSend = { ...formData, images: validImages };

      const response = await axios.post('http://localhost:5600/api/restaurants/', dataToSend, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      toast({
        title: 'Restaurant Registered',
        description: 'Your restaurant has been successfully registered!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      // Reset form fields
      setFormData({
        name: '',
        location: '',
        cuisine: '',
        contact: '',
        images: ['', '', ''],
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error.response?.data?.error || 'Failed to register restaurant',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      maxW="md"
      mx="auto"
      mt={10}
      p={6}
      borderWidth={1}
      borderRadius="lg"
      boxShadow="lg"
    >
      <Heading as="h2" size="lg" textAlign="center" mb={6}>
        Register Restaurant
      </Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl id="name" isRequired>
            <FormLabel>Restaurant Name</FormLabel>
            <Input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter restaurant name"
            />
          </FormControl>

          <FormControl id="location" isRequired>
            <FormLabel>Location</FormLabel>
            <Textarea
              name="location"
              value={formData.location}
              onChange={handleChange}
              placeholder="Enter location"
            />
          </FormControl>

          <FormControl id="cuisine" isRequired>
            <FormLabel>Cuisine</FormLabel>
            <Input
              type="text"
              name="cuisine"
              value={formData.cuisine}
              onChange={handleChange}
              placeholder="Enter cuisine type"
            />
          </FormControl>

          <FormControl id="contact" isRequired>
            <FormLabel>Contact</FormLabel>
            <Input
              type="text"
              name="contact"
              value={formData.contact}
              onChange={handleChange}
              placeholder="Enter contact details"
            />
          </FormControl>

          <FormControl id="images">
            <FormLabel>Image URLs</FormLabel>
            {formData.images.map((image, index) => (
              <Input
                key={index}
                type="url"
                value={image}
                onChange={(e) => handleImageChange(index, e.target.value)}
                placeholder={`Image URL ${index + 1}`}
                mb={2}
              />
            ))}
          </FormControl>

          <Button type="submit" colorScheme="blue" width="full">
            Register
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default RegisterRestaurant;
