import React, { useEffect, useState } from 'react';
import { Box, Grid, GridItem, Text, Spinner, VStack, HStack, Button, Badge, Input, Select } from '@chakra-ui/react';
import axios from 'axios';
import dayjs from 'dayjs'; // Import dayjs to format the date

const Dashboard = () => {
  const [onlineOrders, setOnlineOrders] = useState([]);
  const [codOrders, setCodOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState(''); // For search query
  const [sortOrder, setSortOrder] = useState('desc'); // For sorting (latest to oldest)

  // Fetch online orders and COD orders from the backend
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        // Fetch online orders
        const onlineResponse = await axios.get('https://foodwebbackend-ufii.onrender.com/api/order/orders'); // Replace with your API endpoint
        setOnlineOrders(onlineResponse.data.orders); // Set online orders

        // Fetch COD orders
        const codResponse = await axios.get('https://foodwebbackend-ufii.onrender.com/api/orders/all-cod-orders'); // Your COD orders API
        setCodOrders(codResponse.data.orders); // Set COD orders

        setLoading(false);
      } catch (err) {
        console.error('Error fetching orders:', err);
        setError('Failed to fetch orders');
        setLoading(false);
      }
    };

    fetchOrders();
  }, [sortOrder]); // Fetch orders when sortOrder changes

  // If still loading, show spinner
  if (loading) {
    return (
      <VStack justify="center" align="center" height="100vh">
        <Spinner size="xl" />
        <Text mt={4}>Loading orders...</Text>
      </VStack>
    );
  }

  // If there was an error, show an error message
  if (error) {
    return (
      <VStack justify="center" align="center" height="100vh">
        <Text color="red.500">{error}</Text>
      </VStack>
    );
  }

  // Handle the search functionality for both orders
  const filteredOnlineOrders = onlineOrders.filter((order) =>
    order.customer_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredCodOrders = codOrders.filter((order) =>
    order.customer_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Sort orders by creation date for both online and COD orders
  const sortedOnlineOrders = filteredOnlineOrders.sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
  });

  const sortedCodOrders = filteredCodOrders.sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
  });

  return (
    <Box p={6} overflowY="auto" height="100vh">
      <Text fontSize="2xl" fontWeight="bold" mb={4}>Dashboard: Orders</Text>

      {/* Search input and sorting controls */}
      <HStack spacing={4} mb={4}>
        <Input
          placeholder="Search by name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          maxWidth="300px"
        />
        <Select value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} width="auto">
          <option value="desc">Latest to Oldest</option>
          <option value="asc">Oldest to Latest</option>
        </Select>
      </HStack>

      {/* Display Online Orders */}
      <Text fontSize="xl" fontWeight="bold" mb={4}>Online Orders</Text>
      <Grid templateColumns="repeat(auto-fill, minmax(300px, 1fr))" gap={6}>
        {sortedOnlineOrders.map((order) => (
          <GridItem key={order.razorpay_payment_id} borderWidth="1px" borderRadius="lg" p={4} boxShadow="md">
            <VStack align="start" spacing={4}>
              <Text fontSize="lg" fontWeight="bold">Order ID: {order.razorpay_order_id}</Text>
              <Text>Payment ID: {order.razorpay_payment_id}</Text>
              <Text>Amount: ₹{(order.amount).toFixed(2)}</Text>
              <HStack>
                <Text fontSize="sm" color="gray.500">Status:</Text>
                <Badge colorScheme="green">Paid</Badge>
              </HStack>
              
              {/* Customer Details */}
              <VStack align="start" spacing={1}>
                <Text fontSize="sm" fontWeight="bold">Customer Details:</Text>
                <Text>Name: {order.customer_name}</Text>
                <Text>Email: {order.customer_email}</Text>
                <Text>Contact: {order.customer_contact}</Text>
              </VStack>

              {/* Order Details */}
              <VStack align="start" spacing={1}>
                <Text fontSize="sm" fontWeight="bold">Order Details:</Text>
                {order.order_details.map((item, index) => (
                  <HStack key={index} spacing={2}>
                    <Text>{item.name}</Text>
                    <Text>₹{item.price}</Text>
                  </HStack>
                ))}
              </VStack>

              <Text fontSize="sm" color="gray.500">
                Created At: {dayjs(order.created_at).format('DD/MM/YYYY hh:mm A')}
              </Text>
              <Button colorScheme="blue" size="sm" onClick={() => handleViewDetails(order)}>
                View Details
              </Button>
            </VStack>
          </GridItem>
        ))}
      </Grid>

      {/* Display COD Orders */}
      <Text fontSize="xl" fontWeight="bold" mb={4} mt={6}>COD Orders</Text>
      <Grid templateColumns="repeat(auto-fill, minmax(300px, 1fr))" gap={6}>
        {sortedCodOrders.map((order) => (
          <GridItem key={order._id} borderWidth="1px" borderRadius="lg" p={4} boxShadow="md">
            <VStack align="start" spacing={4}>
              <Text fontSize="lg" fontWeight="bold">Order ID: {order._id}</Text>
              <Text>Payment Method: COD</Text>
              <Text>Amount: ₹{(order.amount).toFixed(2)}</Text>
              <HStack>
                <Text fontSize="sm" color="gray.500">Status:</Text>
                <Badge colorScheme="green">Paid</Badge>
              </HStack>
              
              {/* Customer Details */}
              <VStack align="start" spacing={1}>
                <Text fontSize="sm" fontWeight="bold">Customer Details:</Text>
                <Text>Name: {order.customer_name}</Text>
                <Text>Email: {order.customer_email}</Text>
                <Text>Contact: {order.customer_contact}</Text>
              </VStack>

              {/* Order Details */}
              <VStack align="start" spacing={1}>
                <Text fontSize="sm" fontWeight="bold">Order Details:</Text>
                {order.order_details.map((item, index) => (
                  <HStack key={index} spacing={2}>
                    <Text>{item.name}</Text>
                    <Text>₹{item.price}</Text>
                  </HStack>
                ))}
              </VStack>

              <Text fontSize="sm" color="gray.500">
                Created At: {dayjs(order.created_at).format('DD/MM/YYYY hh:mm A')}
              </Text>
              <Button colorScheme="blue" size="sm" onClick={() => handleViewDetails(order)}>
                View Details
              </Button>
            </VStack>
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};

const handleViewDetails = (order) => {
  // Handle view order details logic (e.g., navigate to a detailed page)
  console.log('View details for order', order);
};

export default Dashboard;
