import React from 'react';
import { Button, Box, Text, Image } from '@chakra-ui/react';

// Import the background image from the assets folder
import bannerImage from '../assests/Download.jpg'; // Replace with your actual file path
import Header from './Header';

const GoogleDriveDownloadBanner = () => {
  // Replace this with the actual Google Drive file link
  const fileUrl = "https://drive.google.com/file/d/1v-8nlxfZO6g5Ow_5mCW1GrqYqY7c93_Q/view?usp=sharing";

  const handleDownloadClick = () => {
    // Redirect to Google Drive file for download
    window.location.href = fileUrl;
  };

  return (
    <>
      <Header />
      <Box
        bgImage={`url(${bannerImage})`} // Set the background image
        bgSize="cover"                 // Ensure the image covers the whole container
        bgPosition="center"            // Center the image in the container
        borderRadius="md"
        boxShadow="0 0 15px rgba(0, 0, 0, 0.3)"
        p={{ base: 4, md: 8 }} 
        paddingLeft="8px"
        paddingRight="8px"        // Responsive padding: 16px for small screens, 32px for medium and larger screens
        mt="66px"
        minHeight="400px"              // Minimum height for the banner
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        w="full"
        maxW="1200px"
        mx="auto"
      >
        {/* Text and Button */}
        <Text 
          fontSize={{ base: "lg", md: "2xl" }} // Responsive font size
          fontWeight="bold" 
          mb={4} 
          textAlign="center" 
          pb={4}
        >
          Download Your File from Google Drive
        </Text>
        <Text 
          fontSize={{ base: "md", md: "lg" }} // Responsive font size
          mb={4} 
          textAlign="center"
        >
          Click the button below to download the file from Google Drive.
        </Text>
        <Button
          colorScheme="teal"
          onClick={handleDownloadClick}
          size="lg"
          borderRadius="md"
          boxShadow="md"
        >
          Download File
        </Button>
      </Box>
    </>
  );
};

export default GoogleDriveDownloadBanner;
