import React from 'react';
import { Box, Text, HStack, Link } from '@chakra-ui/react';

// Updated components with Razorpay and FSSAI links
const TermsAndConditions = () => (
  <Link
    href="https://merchant.razorpay.com/policy/PONdLD4m5MGmcx/terms"
    color="white"
    _hover={{ textDecoration: 'underline' }}
    isExternal
  >
    Terms & Conditions
  </Link>
);

const PrivacyPolicy = () => (
  <Link
    href="https://merchant.razorpay.com/policy/PONdLD4m5MGmcx/privacy"
    color="white"
    _hover={{ textDecoration: 'underline' }}
    isExternal
  >
    Privacy Policy
  </Link>
);

const RefundPolicies = () => (
  <Link
    href="https://merchant.razorpay.com/policy/PONdLD4m5MGmcx/refund"
    color="white"
    _hover={{ textDecoration: 'underline' }}
    isExternal
  >
    Refunds/Cancellations policy
  </Link>
);

const FSSAILink = () => (
  <Link
    href="https://foscos.fssai.gov.in/display-details/105245938/LIC"
    color="white"
    _hover={{ textDecoration: 'underline' }}
    isExternal
  >
    FSSAI
  </Link>
);

const Footer = () => {
  return (
    <Box
      as="footer"
      w="100%"
      bg="gray.900"
      color="white"
      py={12}
      px={{ base: 4, md: 8 }}
      textAlign="center"
      boxShadow="0px -4px 10px rgba(0, 0, 0, 0.1)"
    >
      <HStack
        spacing={6}
        align="center"
        justify="center"
        wrap="wrap"
        mb={8}
      >
        <Text fontSize="sm" fontWeight="bold">
          © 2024 . All Rights Reserved. by Ahujasons JV 
        </Text>
        <TermsAndConditions />
        <PrivacyPolicy />
        <RefundPolicies />
        <FSSAILink />
        <Text fontSize="sm" fontWeight="bold">
          📍 Plot-142 , Sector 63 Noida , INDIA
        </Text>
      </HStack>
    </Box>
  );
};

export default Footer;
